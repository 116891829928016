export const DOCUMENT_FORM = 'DocumentForm'

export const FIELD_TEXT = 'text'
export const FIELD_PHONE = 'phone'
export const FIELD_EMAIL = 'email'
export const FIELD_DATE = 'date'
export const FIELD_DATE_PICKER = 'date_picker'
export const FIELD_PASSWORD = 'password'
export const FIELD_SELECT = 'select'
export const FIELD_RADIO = 'radio'
export const FIELD_TEXTAREA = 'textarea'
export const FIELD_SCALE = 'scale'
export const FIELD_HIDDEN = 'hidden'
export const FIELD_CHECKBOX = 'checkbox'
export const FIELD_FILES = 'files'
export const FIELD_UPLOADER = 'file_uploader'
export const VALUE_HABITATION_APARTMENT = 'apartment'

export const FIELDS = [FIELD_SELECT, FIELD_RADIO, FIELD_TEXTAREA, FIELD_SCALE]

export const VERIFY_CHECKING_CONDITION_FORM = 'VERIFY_CHECKING_CONDITION_FORM'

export const JOB_CLIENT_STEPPER_FORM_STEP_ONE =
  'JOB_CLIENT_STEPPER_FORM_STEP_ONE'

export const JOB_CLIENT_STEPPER_FORM_STEP_TWO =
  'JOB_CLIENT_STEPPER_FORM_STEP_TWO'

export const JOB_CLIENT_STEPPER_FORM_STEP_THREE =
  'JOB_CLIENT_STEPPER_FORM_STEP_THREE'

export const JOB_CLIENT_STEPPER_FORM_STEP_FOUR =
  'JOB_CLIENT_STEPPER_FORM_STEP_FOUR'

export const JOB_CLIENT_FORM_STEP_ONE_ID = 'JobClientFormStepOne_'
export const JOB_CLIENT_FORM_STEP_TWO_ID = 'JobClientFormStepTwo_'
export const JOB_CLIENT_FORM_STEP_THREE_ID = 'JobClientFormStepThree_'
export const JOB_CLIENT_FORM_STEP_FOUR_ID = 'JobClientFormStepFour_'

export const JOB_CHECKIN_FORM_STEP_ONE_ID = 'JobCheckinFormStepOne_'
export const JOB_CHECKIN_FORM_STEP_TWO_ID = 'JobCheckinFormStepTwo_'
export const JOB_CHECKIN_FORM_STEP_THREE_ID = 'JobCheckinFormStepThree_'

export const JOB_CHECKIN_STEPPER_FORM_STEP_ONE =
  'JOB_CHECKIN_STEPPER_FORM_STEP_ONE'
export const JOB_CHECKIN_STEPPER_FORM_STEP_THREE =
  'JOB_CHECKIN_STEPPER_FORM_STEP_THREE'

export const QUESTION_TYPE_TEXT = 'text'
export const QUESTION_SINGLE_CHOICE = 'single_choice'
export const QUESTION_MULTIPLE_CHOICE = 'multiple_choice'
export const QUESTION_SCALE_UP_TO_5 = 'up_to_5'
export const QUESTION_SCALE_UP_TO_10 = 'up_to_10'

export const GENDER_M = 'Mr'
export const GENDER_MME = 'Mrs'
