import {
  generateRequestActions,
  createRequestTypes,
  generateSimpleAction,
} from 'utils/actionHelpers'
import {
  JOB_ACCEPT_TRANSITION,
  JOB_CLIENT_FORM,
  JOB_CLIENT_STEPPER_TRANSITION,
  JOB_DECLINE_TRANSITION,
} from 'constants/job'

export const GET_ALL_JOBS = 'GET_ALL_JOBS'
export const SET_ALL_JOBS_IS_LOADING = 'SET_ALL_JOBS_IS_LOADING'
export const JOBS = 'JOBS'
export const SET_CURRENT_JOB_ID = 'SET_CURRENT_JOB_ID'
export const SET_CURRENT_GEOLOC_PRO = 'SET_CURRENT_GEOLOC_PRO'
export const ESTIMATED_HOUR_MAP = 'ESTIMATED_HOUR_MAP'
export const GET_JOB = createRequestTypes('GET_JOB')
export const GET_JOB_CUSTOMER = createRequestTypes('GET_JOB_CUSTOMER')
export const UPDATE_JOB = createRequestTypes('UPDATE_JOB')
export const GET_RECEIPT_JOB = createRequestTypes('GET_RECEIPT_JOB')
export const GET_RECEIPT_JOB_FILE = createRequestTypes('GET_RECEIPT_JOB_FILE')
export const OPEN_RECEIPT_JOB_FILE = 'OPEN_RECEIPT_JOB_FILE'
export const VALIDATE_RECEIPT = createRequestTypes('VALIDATE_RECEIPT')
export const GET_RECEIPT_SIGNATURE = createRequestTypes('GET_RECEIPT_SIGNATURE')
export const GET_INVOICE_JOB = createRequestTypes('GET_INVOICE_JOB')
export const TRANSITION_INVOICE_JOB = createRequestTypes(
  'TRANSITION_INVOICE_JOB',
)
export const GET_JOB_TOKEN_FIREBASE = createRequestTypes(
  'GET_JOB_TOKEN_FIREBASE',
)

export const SUBMIT_VERIFY_CHECKING_CONDITION_FORM = createRequestTypes(
  'SUBMIT_VERIFY_CHECKING_CONDITION_FORM',
)

export const JOBS_REQ = createRequestTypes(JOBS)
export const JOB_ACCEPT_TRANSITION_REQ = createRequestTypes(
  JOB_ACCEPT_TRANSITION,
)
export const JOB_DECLINE_TRANSITION_REQ = createRequestTypes(
  JOB_DECLINE_TRANSITION,
)
export const getAllJobs = generateSimpleAction(GET_ALL_JOBS)
export const setAllJobsIsLoading = generateSimpleAction(SET_ALL_JOBS_IS_LOADING)

export const jobs = generateRequestActions(JOBS_REQ)
export const jobAcceptTransition = generateRequestActions(
  JOB_ACCEPT_TRANSITION_REQ,
)
export const jobDeclineTransition = generateRequestActions(
  JOB_DECLINE_TRANSITION_REQ,
)

export const setCurrentJobId = generateSimpleAction(SET_CURRENT_JOB_ID)
export const getJobCustomer = generateRequestActions(GET_JOB_CUSTOMER)
export const getJob = generateRequestActions(GET_JOB)
export const updateJob = generateRequestActions(UPDATE_JOB)
export const getReceiptJob = generateRequestActions(GET_RECEIPT_JOB)
export const getReceiptJobFile = generateRequestActions(GET_RECEIPT_JOB_FILE)
export const openReceiptJobFile = generateSimpleAction(OPEN_RECEIPT_JOB_FILE)
export const validateReceipt = generateRequestActions(VALIDATE_RECEIPT)
export const getReceiptSignature = generateRequestActions(GET_RECEIPT_SIGNATURE)
export const getInvoiceJob = generateRequestActions(GET_INVOICE_JOB)
export const transitionInvoiceJob = generateRequestActions(
  TRANSITION_INVOICE_JOB,
)
export const getJobTokenFirebase = generateRequestActions(
  GET_JOB_TOKEN_FIREBASE,
)
export const submitVerifyCheckingConditionForm = generateRequestActions(
  SUBMIT_VERIFY_CHECKING_CONDITION_FORM,
)

export const GET_CHECKING_CONDITION_LIST = createRequestTypes(
  'GET_CHECKING_CONDITION_LIST',
)
export const getCheckingConditionList = generateRequestActions(
  GET_CHECKING_CONDITION_LIST,
)
export const setCurrentGeolocPro = generateSimpleAction(SET_CURRENT_GEOLOC_PRO)
export const setEstimatedHourMap = generateSimpleAction(ESTIMATED_HOUR_MAP)
export const GET_PRO_PHONE_NUMBER = createRequestTypes('GET_PRO_PHONE_NUMBER')
export const getProPhoneNumber = generateRequestActions(GET_PRO_PHONE_NUMBER)
export const SHARE_JOB_INFOS = createRequestTypes('SHARE_JOB_INFOS')
export const shareJobInfos = generateRequestActions(SHARE_JOB_INFOS)

export const SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_ONE =
  'SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_ONE'
export const submitTrackingFillInHoInformationFormStepOne =
  generateSimpleAction(SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_ONE)

export const SUBMIT_JOB_CLIENT_FORM_STEP_ONE = 'SUBMIT_JOB_CLIENT_FORM_STEP_ONE'
export const submitJobClientFormStepOne = generateSimpleAction(
  SUBMIT_JOB_CLIENT_FORM_STEP_ONE,
)

export const SUBMIT_JOB_CLIENT_FORM_STEP_TWO = 'SUBMIT_JOB_CLIENT_FORM_STEP_TWO'
export const submitJobClientFormStepTwo = generateSimpleAction(
  SUBMIT_JOB_CLIENT_FORM_STEP_TWO,
)

export const SUBMIT_JOB_CLIENT_FORM_STEP_THREE =
  'SUBMIT_JOB_CLIENT_FORM_STEP_THREE'
export const submitJobClientFormStepThree = generateSimpleAction(
  SUBMIT_JOB_CLIENT_FORM_STEP_THREE,
)

export const GET_STEP_FOUR_FORM_QUESTIONS = createRequestTypes(
  'GET_STEP_FOUR_FORM_QUESTIONS',
)
export const getStepFourFormQuestions = generateRequestActions(
  GET_STEP_FOUR_FORM_QUESTIONS,
)

export const SUBMIT_JOB_CLIENT_FORM_STEP_FOUR =
  'SUBMIT_JOB_CLIENT_FORM_STEP_FOUR'
export const submitJobClientFormStepFour = generateSimpleAction(
  SUBMIT_JOB_CLIENT_FORM_STEP_FOUR,
)

export const SUBMIT_JOB_CLIENT_FORM_REQ = createRequestTypes(JOB_CLIENT_FORM)
export const submitJobClientForm = generateRequestActions(
  SUBMIT_JOB_CLIENT_FORM_REQ,
)

export const SUBMIT_JOB_CLIENT_FORM_PACKAGE_CHECKIN_REQ = createRequestTypes(
  'SUBMIT_JOB_CLIENT_FORM_PACKAGE_CHECKIN_REQ',
)
export const submitJobClientFormPackageCheckin = generateRequestActions(
  SUBMIT_JOB_CLIENT_FORM_PACKAGE_CHECKIN_REQ,
)

export const JOB_CLIENT_STEPPER_TRANSITION_REQ = createRequestTypes(
  JOB_CLIENT_STEPPER_TRANSITION,
)
export const jobClientStepperTransition = generateRequestActions(
  JOB_CLIENT_STEPPER_TRANSITION_REQ,
)

export const SUBMIT_JOB_CHECKIN_FORM_STEP_ONE =
  'SUBMIT_JOB_CHECKIN_FORM_STEP_ONE'
export const submitJobCheckinFormStepOne = generateSimpleAction(
  SUBMIT_JOB_CHECKIN_FORM_STEP_ONE,
)

export const SUBMIT_JOB_CHECKIN_FORM_STEP_TWO =
  'SUBMIT_JOB_CHECKIN_FORM_STEP_TWO'
export const submitJobCheckinFormStepTwo = generateSimpleAction(
  SUBMIT_JOB_CHECKIN_FORM_STEP_TWO,
)

export const SUBMIT_JOB_CHECKIN_FORM_STEP_TWO_REQ = createRequestTypes(
  'SUBMIT_JOB_CHECKIN_FORM_STEP_TWO_REQ',
)
export const submitJobCheckinFormStepTwoReq = generateRequestActions(
  SUBMIT_JOB_CHECKIN_FORM_STEP_TWO_REQ,
)

export const SUBMIT_JOB_CHECKIN_FORM_STEP_THREE =
  'SUBMIT_JOB_CHECKIN_FORM_STEP_THREE'
export const submitJobCheckinFormStepThree = generateSimpleAction(
  SUBMIT_JOB_CHECKIN_FORM_STEP_THREE,
)

export const SUBMIT_JOB_CHECKIN_FORM_STEP_THREE_REQ = createRequestTypes(
  'SUBMIT_JOB_CHECKIN_FORM_STEP_THREE_REQ',
)
export const submitJobCheckinFormStepThreeReq = generateRequestActions(
  SUBMIT_JOB_CHECKIN_FORM_STEP_THREE_REQ,
)

export const SUBMIT_NEW_DELIVERY_DATE_REQ = createRequestTypes(
  'SUBMIT_NEW_DELIVERY_DATE_REQ',
)
export const submitNewDeliveryDate = generateRequestActions(
  SUBMIT_NEW_DELIVERY_DATE_REQ,
)

export const SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_TWO =
  'SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_TWO'
export const submitTrackingFillInHoInformationFormStepTwo =
  generateSimpleAction(SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_TWO)

export const SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_THREE =
  'SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_THREE'
export const submitTrackingFillInHoInformationFormStepThree =
  generateSimpleAction(SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_THREE)

export const SET_TRACKING_FILL_IN_HO_INFORMATION_FORM_ERROR =
  'SET_TRACKING_FILL_IN_HO_INFORMATION_FORM_ERROR'
export const setTrackingFillInHoInformationFormError = generateSimpleAction(
  SET_TRACKING_FILL_IN_HO_INFORMATION_FORM_ERROR,
)

export const UPLOAD_HO_PICTURE_TO_CLOUDINARY = createRequestTypes(
  'UPLOAD_HO_PICTURE_TO_CLOUDINARY',
)
export const uploadHoPictureToCloudinary = generateRequestActions(
  UPLOAD_HO_PICTURE_TO_CLOUDINARY,
)

export const MULTIPLE_UPLOAD_HO_FILE = createRequestTypes(
  'MULTIPLE_UPLOAD_HO_FILE',
)
export const multipleUploadHoFile = generateRequestActions(
  MULTIPLE_UPLOAD_HO_FILE,
)

export const SAVE_TRACKING_FILL_IN_HO_INFORMATION = createRequestTypes(
  'SAVE_TRACKING_FILL_IN_HO_INFORMATION',
)
export const saveTrackingFillInHoInformation = generateRequestActions(
  SAVE_TRACKING_FILL_IN_HO_INFORMATION,
)

export const UPDATE_JOB_TIMESLOTS = createRequestTypes('UPDATE_JOB_TIMESLOTS')
export const updateJobTimeslots = generateRequestActions(UPDATE_JOB_TIMESLOTS)

export const UPDATE_JOB_FROM_HO_FILLING_INFORMATION = createRequestTypes(
  'UPDATE_JOB_FROM_HO_FILLING_INFORMATION',
)
export const updateJobFromHoFillingInformation = generateRequestActions(
  UPDATE_JOB_FROM_HO_FILLING_INFORMATION,
)

export const DELETE_JOB_PICTURE = createRequestTypes('DELETE_JOB_PICTURE')
export const deleteJobPicture = generateRequestActions(DELETE_JOB_PICTURE)

export const POST_NEW_TIMESLOTS_FORM_ERROR = 'POST_NEW_TIMESLOTS_FORM_ERROR'
export const postNewTimeslotsFormError = generateSimpleAction(
  POST_NEW_TIMESLOTS_FORM_ERROR,
)

export const SUBMIT_NEW_TIMESLOTS_FORM = createRequestTypes(
  'SUBMIT_NEW_TIMESLOTS_FORM',
)
export const submitNewTimeslotsForm = generateRequestActions(
  SUBMIT_NEW_TIMESLOTS_FORM,
)
